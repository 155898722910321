'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useMemo } from 'react';

import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import { Provider } from 'react-redux';

import type { TAgencyAgentResponse } from '@/infra/types/fmlink/agent';

import FMBanner from '@/components/FMBanner';
import { BookingChannelTypes } from '@/constants/settings';
import MobileDeviceContext from '@/contexts/MobileDeviceContext';
import VWOGlobalFeatureContext from '@/contexts/VWOGlobalFeatureContext';
import { resetUserToken } from '@/ducks/auth/actions';
import { selectDefaultCurrency } from '@/ducks/common/lookup/selectors';
import { setCurrencyCode } from '@/ducks/filters';
import { selectAgentCurrencyCode } from '@/ducks/fm/selectors';
import { removeAgencyData, setAgencyData } from '@/ducks/fm/setters';
import { AgentDataActionType } from '@/ducks/fm/types';
import useStorePersistentAligners from '@/ducks/persistent/useStorePersistentAligners';
import { checkAllProgramKeys } from '@/ducks/programs/all/actions';
import { ApplyBFColors } from '@/ducks/programs/blackFriday/hooks';
import useRouteChangeObserver from '@/ducks/routeChange/useRouteChangeObserver';
import { setPathname, setRouter, setSearchParams } from '@/ducks/routes/history';
import { useUrlParamObservers } from '@/ducks/urlParams/base';
import urlParamObservers from '@/ducks/urlParams/observers';
import { initApiDefaults } from '@/helpers/api/core';
import { ApolloWrapper } from '@/helpers/api/graphql/ApolloWrapper';
import { AppAuthenticator, type AppTokens } from '@/helpers/api/tokens';
import CMSContextProvider from '@/helpers/cms/CMSContextProvider';
import { FormattedMessageProvider } from '@/helpers/formatted-message';
import { googleTagManagerCode, setupWindowCode, vwoInitCode } from '@/helpers/inlineScripts';
import useInitAccountDropdownEvents from '@/helpers/managers/account-dropdown/useInitAccountDropdownEvents';
import { toBlobPolyfill } from '@/helpers/util';
import { initLogger } from '@/helpers/util/logger';
import useVWOTrackFunnelDropoff from '@/helpers/vwo/useVWOTrackFunnelDropoff';
import { useAsyncRouter } from '@/hooks/router/useAsyncRouter';
import { useCatchAsyncError } from '@/hooks/useAsyncError';
import useOnceOnClient from '@/hooks/useOnceOnClient';
import usePrevPathPreservation from '@/hooks/usePrevPathPreservation';
import { useInitUplift } from '@/infra/uplift';
import WithChatbot from '@/layouts/Wrappers/Chatbot';
import { type RootState, setupStore, useAppDispatch } from '@/store';
import tagmanager from '@/tagmanager';

import './Base.scss';

type BaseLayoutProps = {
  campaign: Record<string, unknown>;
  children: React.ReactNode;
  currencyCode: string;
  isMobile: boolean;
  legalContent: Record<string, unknown>;
  tokens: AppTokens;
};

const BaseLayout = ({ campaign, children, currencyCode, isMobile, legalContent, tokens }: BaseLayoutProps) => {
  AppAuthenticator.getInstance().setRawTokens(tokens);
  const dispatch = useAppDispatch();
  const pathname = usePathname();
  const searchParams = useSearchParams()!;
  const urlCurrency = searchParams?.get('currencyCode');

  useUrlParamObservers(urlParamObservers);
  useRouteChangeObserver();
  useOnceOnClient(initLogger);
  useCatchAsyncError();

  useEffect(() => {
    tagmanager.tracker.anyBookFunnel.trackPageOpen(pathname);
  }, [pathname]);

  useEffect(() => {
    googleTagManagerCode();
    toBlobPolyfill();
    vwoInitCode();
    setupWindowCode();
    initApiDefaults((userId: string) => dispatch(resetUserToken(userId)));

    dispatch(checkAllProgramKeys());
  }, [dispatch]);

  useEffect(() => {
    if (currencyCode && !urlCurrency) {
      dispatch(setCurrencyCode(currencyCode, false));
    }
  }, [urlCurrency, currencyCode, dispatch]);

  useStorePersistentAligners();
  useInitUplift();
  usePrevPathPreservation();
  useInitAccountDropdownEvents();
  useVWOTrackFunnelDropoff();

  return (
    <>
      <ApplyBFColors />
      <MobileDeviceContext.Provider value={isMobile}>
        <FormattedMessageProvider>
          <CMSContextProvider campaign={campaign} legalContent={legalContent}>
            <WithChatbot>{children}</WithChatbot>
          </CMSContextProvider>
        </FormattedMessageProvider>
      </MobileDeviceContext.Provider>
    </>
  );
};

type BaseLayoutWrapperProps = {
  agentAction: string;
  campaign: Record<string, unknown>;
  children: React.ReactNode;
  featureFlags: Record<string, boolean>;
  initialState: RootState;
  isMobile: boolean;
  legalContent: Record<string, unknown>;
  tokens: AppTokens;
};

const useHackForRouter = () => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useAsyncRouter();

  setSearchParams(searchParams);
  setPathname(pathname);
  setRouter(router);
};

const useSetupStore = (initialState: RootState) => {
  return useMemo(
    () => setupStore(initialState, { reconfigure: true }),
    // Don't add deps as it will trigger making store from scratch
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

const useUpdateAgentDetails = ({ action, response }: { action: string; response?: TAgencyAgentResponse }) => {
  useEffect(() => {
    if (action === AgentDataActionType.remove) {
      removeAgencyData();
    } else if (response && action !== AgentDataActionType.exist) {
      removeAgencyData();
      if (response?.agentDetails?.isValid && response?.agentDetails?.bookingChannel === BookingChannelTypes.fmLink) {
        setAgencyData(response);
      }
    }
  }, [action, response]);
};

const BaseLayoutWrapper = ({
  agentAction,
  campaign,
  children,
  featureFlags,
  initialState,
  isMobile,
  legalContent,
  tokens,
}: BaseLayoutWrapperProps) => {
  useHackForRouter();
  AppAuthenticator.getInstance().setRawTokens(tokens);
  const { agentData } = initialState;
  useUpdateAgentDetails({ action: agentAction, response: agentData });
  const store = useSetupStore(initialState);
  const currencyCode = selectAgentCurrencyCode(initialState) || selectDefaultCurrency(initialState);

  return (
    <Provider store={store}>
      <VWOGlobalFeatureContext.Provider value={featureFlags}>
        <ApolloWrapper>
          <BaseLayout
            campaign={campaign}
            currencyCode={currencyCode}
            isMobile={isMobile}
            legalContent={legalContent}
            tokens={tokens}
          >
            <FMBanner />
            <div className="Layout__content">{children}</div>
            <ProgressBar color="#c00" height="2px" options={{ showSpinner: false }} shallowRouting />
          </BaseLayout>
        </ApolloWrapper>
      </VWOGlobalFeatureContext.Provider>
    </Provider>
  );
};

export default BaseLayoutWrapper;
